:root {
    --brand-color: rgb(24, 34, 86);
    ;
    --text-color: #303030
}

body {
    font-family: "Gill Sans", sans-serif;
    color: var(--text-color);
    background: #f6f9ff;
    background: -webkit-linear-gradient(to right, #ffffff, #dde1e9);
    background: linear-gradient(to right, #ffffff, #dde1e9);

}

.authWrap .row {
    min-height: 100vh;
}

.display-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0px 15px rgb(82 63 105 / 15%);
    padding: 1.5rem 1rem;
    position: relative;
}

.authWrap h1 {
    font-size: 22px;
    font-weight: 600;
}

.authWrap p {
    font-size: 14px;
    color: #797979;
}

.siteMenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
}

.siteMenu ul li {
    display: inline-block;
    padding-left: 15px;
}

.univ-btn,
.univ-btn-light,
.univ-btn-outline {
    background: var(--brand-color);
    color: #fff;
    font-size: 14px;
    border: 0;
    padding: 12px 30px;
    border-radius: 8px;
    outline: none;
    transition: all ease 0.6s;
    display: inline-block;
    border: 1px solid var(--brand-color);
}

.univ-btn-login {
    background: white;
    color: rgb(24, 34, 86);
    ;
    font-size: 14px;
    border: 0;
    padding: 12px 30px;
    border-radius: 8px;
    outline: none;
    transition: all ease 0.6s;
    display: inline-block;
    border: 1px solid rgb(24, 34, 86);
    ;
}

.univ-btn-outline {
    background: transparent;
    border: 1px solid var(--brand-color);
    color: var(--brand-color);
}

.univ-btn:hover,
.univ-btn-outline:hover {
    transition: all ease 0.6s;
    box-shadow: 0 4px 20px rgb(0 0 0 / 43%);
    background: var(--brand-color);
    color: #fff;
}

.univ-btn-light {
    background: #fff;
    color: var(--brand-color);
}

.brand-color {
    color: var(--brand-color);
}

header {
    padding: 10px 0;
    position: absolute;
    width: 100%;
}

/* .bannerWrap{ */
/* background: url('../images/banner-bg.jpg') no-repeat; */
/* background-color: var(--brand-color); */
/* background-size: cover;
    padding: 3rem;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0px 15px rgb(0 0 0 / 5%);
    border-radius: 15px; */
/* } */
.bannerWrap h1 {
    font-size: 36px;
    font-weight: 600;
    color: #fff;
}

.bannerWrap button.univ-btn-light {
    width: 300px;
}

.bannerWrap p,
.worksContent p,
.worksContent ul li {
    font-size: 14px;
    color: #fff;
    line-height: 26px;
}

.section-title h2 {
    font-size: 26px;
    margin: 0;
    font-weight: 600;
}

.feature-block {
    padding: 1rem 1.5rem;
    transition: all ease 0.6s;
}

.feature-block:hover {
    background: #fff;
    box-shadow: 0 0px 15px rgb(0 0 0 / 5%);
    border-radius: 10px;
    transition: all ease 0.6s;
}

.feature-block h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.feature-block p {
    font-size: 14px;
}

.feature-block img {
    max-width: 60px;
    margin: 1rem auto;
}

.worksImage img {
    width: 100%;
    border-radius: 12px;
}

.worksContent h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.worksContent p,
.worksContent ul li {
    color: var(--text-color);
}

.worksContent {
    background: #fff;
    padding: 3rem;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 5%);
}

.copyrightText p,
.authLogin p {
    font-size: 14px;
}

.modal-header {
    border: 0;
    padding-bottom: 0;
}

.loginModal .modal-content {
    border: 0;
    border-radius: 15px;
    max-width: 350px;
    margin: auto;
}

.modal-body {
    padding: 20px 25px 30px 25px;
}

.authLogin h2,
.docUpload h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
}

.authLogin .googleLogin {
    width: 100%;
    background: transparent;
    outline: none;
    border: 1px solid #ddd;
    font-size: 14px;
    padding: 6px;
    border-radius: 10px;
}

.authLogin .googleLogin img {
    max-width: 30px;
}

.authLogin label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.univ-input {
    width: 100%;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 8px 15px;
    outline: none;
}

.docUpload h2 {
    font-size: 16px;
    margin-bottom: 10px;
}

.uploadWrap {
    border: 1px dashed #ddd;
    border-radius: 10px;
    padding: 15px;
    background: #f9f9f9;
}

.uploadWrap svg {
    font-size: 45px;
    margin-bottom: 10px;
}

.uploadWrap h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.uploadWrap p {
    font-size: 12px;
    font-style: italic;
}

.file-upload__label {
    display: inline-block;
    padding: 8px 30px;
    color: var(--brand-color);
    border: 1px solid var(--brand-color);
    border-radius: 0.4em;
    transition: background .3s;
    margin-top: 10px;
    cursor: pointer;
    font-size: 14px;
}

.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}

.templateProgress ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
}

.templateProgress ul li {
    padding: 15px;
    transition: all ease 0.6s;
}

.templateProgress ul li:hover {
    box-shadow: 0 4px 15px rgb(0 0 0 / 15%);
    transition: all ease 0.6s;
    background: #f4f8ff;
    border-radius: 10px;
}

.templateProgress .fileIcon {
    font-size: 35px;
    margin-right: 10px;
}

.templateProgress .templateName {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
}

.templateProgress .progress {
    height: 10px;
}

.templateDetail {
    width: 80%;
}

.deleteIcon svg {
    font-size: 25px;
    cursor: pointer;
}

@media (max-width: 767px) {
    .bannerWrap button.univ-btn-light {
        width: calc(100% - 20px);

    }
}

.banner-section {
    background: rgb(24, 34, 86);
    background: linear-gradient(264deg, rgba(24, 34, 86, 1) 0%, rgba(49, 62, 132, 1) 100%);
    min-height: 80vh;
    display: flex;
    align-items: center;
}

.how-it-works {
    background: rgb(2, 0, 36);
    background: linear-gradient(243deg, rgba(2, 0, 36, 1) 0%, rgba(24, 34, 86, 0.9640231092436975) 8%, rgba(0, 212, 255, 1) 100%);
    color: white;
}

.im {
    border-radius: 1%;
}

/* FixedCard.css */
.fixed-card {
    width: 300px;
    /* Fixed width */
    height: 200px;
    /* Fixed height */
    background-color: white;
    /* Background color */
    overflow: hidden;
    /* Hide overflow if content is larger */
    border: 1px solid #ddd;
    /* Optional: border for visibility */
    border-radius: 15px;
    /* Rounded corners */
    color: var(--brand-color);
}