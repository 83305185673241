/* sidebar.css */

:root{
    --brand-color:rgb(24, 34, 86);;
    --text-color: #303030
}
body{
    font-family:  "poppins", sans-serif;
    color: var(--text-color);
    background: #f6f9ff; 
    background: -webkit-linear-gradient(to right, #ffffff, #dde1e9);
    background: linear-gradient(to right, #ffffff, #dde1e9);

}


.sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 300px; 
    background-color: var(--brand-color);
    transition: all 0.2s;
    border-radius: 18px; 
}

#side_nav {
    height: 95vh;
    min-width: 300px;
    max-width: 300px;
    transition: all 0.2s;
    border-radius: 18px; 
    overflow-x: hidden; 
}


.content-1 {
  width: 80% !important; 
  transition: all 0.2s;
  border-radius: 18px; 
  overflow-x: hidden;  
}



.content-2{
    width: 60% !important; 
    display: flex;
    flex-direction: column-reverse;
}


.content {
    min-height: 100vh;
    width: 100%;   
}

.sidebr.active {
    background-color: var(--brand-color);
}

a.text-decoration-none.sidebar-active.active {
    width: 100%;
    display: flex;
}

.sidebar li:hover {
    background-color: #4D8B9E;
  }

.title-topic {
    color: #B6B6B6;
    font-size: 14px;
}
.top-content p{
  font-size: 14px;
}
  .sidebr {
    background-color: transparent;
    font-size: 14px;
    align-items: center;
    margin: 0px;
    padding: 0px 24px 0px calc(20px);
    height: 40px;
    display: flex;
    color: white;
    cursor: pointer;
    color: white;
    
  }
  
  .sidebr:hover {
    background-color: #4D8B9E;
    color: white;
    font-weight: 500;
  }
  .logout-button {
   font-size: 14px;
    background-color: #4D8B9E; 
    color: white; 
    border: none; 
    border-radius: 6px !important; 
    cursor: pointer; 
  }
  
  .logout-button:hover {
    background-color: #4a97ae; 
  }

@media (max-width: 767px) {
  #side_nav {
    margin-left: -3200px;
    position: fixed;
    min-height: 70vh;
    z-index: 1;
}
  .content-1 {
    width: 100% !important;  
}
.content-2 {
    width: 100% !important;  
}
button.btn.open-btn {
    position: absolute;
    top: 0.5px !important;
    text-align: right;
    right: 37px !important;
    outline: none;
    border: none;
}
button.btn.close-btn {
    outline: none;
    border: none;
}
.content {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.w-100-mob {
    width: 100% !important;
  }
  .content-1 {
    width: 100% !important;  
}
.ms-4 {
  margin-left: 0rem!important;
}
textarea {
  font-size: x-small !important;
}

}

@media (max-width: 991.98px) {   
  #side_nav {
      margin-left: -3200px;
      position: fixed;
      min-height: 80vh;
      z-index: 1;
  }
  #side_nav.active {
      margin-left: 0;
  }
  button.btn.close-btn {
      position: absolute;
      right: 0;
      font-size: 20px;
      top: 0;
  }
  button.btn.open-btn {
      position: absolute;
      top: 0px !important;
      text-align: right;
      right: 116px;
      outline: none;
      border: none;
  }
  button.btn.close-btn {
      outline: none;
      border: none;
  }
  .content-1 {
    width: 100% !important;  
}
.ms-4 {
  margin-left: 0rem!important;
}
textarea {
  font-size: x-small !important;
}

}

/* textbox css  */

button {
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  .wrapper {
    max-width: 100%;
    position: relative;
  }
  
  .wrapper textarea {
    width: 100%;
    border: none;
    background: #fff; 
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
    outline: none;
    padding: 12px 15px;
    box-sizing: border-box;
    transition: 0.3s;
    font-size: 14px;
  }
  
  .wrapper button {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 45%;
    right: 15px;
    background: transparent;
    transform: translate(0, -50%);
    padding: 0;
  }
  .wrapper button svg{
    width: 35px;
    height: 35px;
  }
  .chatWrapper {
      min-height: 100vh;
      display: flex;
      align-items: center;
      overflow: auto !important;
  }

  /* chat card */

  /* chat css  */

.chat-title-text
{ 
    /* text-shadow: 0 4px 4px rgb(0 0 0 / 25%); */
    font-weight: bold;
}
.chat-title-valmiki
{
    color: white
}

.chat-title-geneseaction
{
    color: #FFFFFF
}
.card-feature {
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  
  .card-feature:hover,.card-feature-answer:hover {
    background: #fff;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    transition: all ease 0.6s;
  }
  
  .card-feature h3 , .card-feature-answer h3{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--brand-color);
  }
  
  .card-feature p {
    font-size: 14px;
    color: #b6b6b6;
  }
  
  .feature-card-title h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 992px) {
    br {
        display: none;
    }
    .col-2{
        width: auto;
    }
    .card-feature {
      padding: 2rem 1rem;
    }
  
    .card-feature h3 {
      font-size: 14px;
    }
  
    .card-feature p {
      font-size: 12px;
    }
  
    .feature-card-title h3 {
      font-size: 16px;
    }
  }
  
  @media (max-width: 768px) {
    br {
        display: none;
    }
    .col-2{
        width: auto;
    }
    .card-feature {
      padding: 1.5rem 0.5rem;
    }
  
    .card-feature h3 {
      font-size: 12px;
    }
  
    .card-feature p {
      font-size: 10px;
    }
  
    .feature-card-title h3 {
      font-size: 14px;
    }
  }
  
  .message{
    color: #5e777f !important;
  }
  .genese-message{
    color: var(--brand-color) !important;
  }

  .card-feature-answer {
    width: fit-content;
    border: none;
    background: #fff; 
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
    outline: none;
    padding: 12px 15px;
    box-sizing: border-box;
    transition: 0.3s;
    font-size: 14px;
  }
  
  .sidebar-head {
    background-color: rgb(24, 34, 86);;
    color: #f6f9ff;
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    padding: 10px 20px 20px 20px;
    border-radius: 50%; 
    width: 40px;
    height: 40px;
  
  }
  
  .card-feature-question {
    width: fit-content;
    border: none;
    background: #7ec7d0;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
    outline: none;
    padding: 12px 15px;
    box-sizing: border-box;
    transition: 0.3s;
    font-size: 14px;
}


  
  
    
  
  
    
  
  
  
